import React from "react"
import { Link } from "gatsby"
import SavingsCard from "../../images/graphs/desktop/savingscard.jpg"
import Magnify_icon from "../../images/magnify-icon.svg"
import websiteIcon from "../../images/3.2/website_icons_v2-03.png"
import blinkcard from "../../images/get-vascepa-now/blinkrx.jpg"
import blinkcard2 from "../../images/get-vascepa-now/blinkrx-card.png"

import "./styles.css"

export default props => {
  function goModal(evt) {
    const dataAtt = evt.target.dataset.modal
    const href = evt.target.dataset.href
    props.goModal(dataAtt, href)
  }

  return (
    <main className="center-grid mobile-m-b-20">
      <h2
        className="cv-headline new-headline-style"
      >
        VASCEPA is available at a lower out-of-pocket cost than generic icosapent
        ethyl for most patients<sup>1</sup>*
      </h2>
      <div className="outcomes-callout"></div>

      <hr />




      <article id="savings" className="endpoints">
        <h2 className="cv-headline ">
          VASCEPA Savings Card
        </h2>
        <div className="chart-container-centering">
          <div className="chart-container">
            <div className="chartlockup">
              <img
                className="SavingsCard"
                src={SavingsCard}
                alt="VASCEPA Savings Card"
              />
              <img
                className="magnify_icon"
                data-modal="savingsCardModal"
                src={Magnify_icon}
                onClick={goModal}
                alt=""
              />
            </div>
          </div>
          <div className="chart-sidebar-no-chart-header">
            <ul className="content-list">
              <li className="primary-list-item">
                <strong>Savings: </strong>For eligible patients,
                VASCEPA is also available for as low as $9 for 90 days with the VASCEPA
                Savings Card<sup>&dagger;</sup>
              </li>
            </ul>
            <div className="btn-group-inline m-b-20">
              <a
                target="_blank"
                href="https://amarincorp.com/docs/VASCEPA_Savings_Card.pdf"
                className="homeBtn"
              >
                Get the savings card
              </a>
              <a
                target="_blank"
                href="https://amarincorp.com/docs/VASCEPA_Spanish_Savings_Brochure.pdf"
                className="homeBtn"
              >
                Get savings info in Spanish
              </a>
            </div>
            <p className=" mobile-m-t-12" >
            For eligible patients only. Maximum savings and other terms and conditions apply. See{" "}
            <strong><a
                href="https://vascepa.copaysavingsprogram.com"
                target="_blank"
                rel="noreferer"
              >
                https://vascepa.copaysavingsprogram.com
              </a>{" "}</strong>
              for details.
            </p>
            
          </div>
        </div>
      </article>
      <hr />

      <article id="section2" className="endpoints">
        <h2 className="cv-headline ">
          Exclusively with BlinkRx, more options to support VASCEPA patients
        </h2>
        <div className="chart-container-centering column">
          <div className="chart-container">
            <div className="chartlockup">
              <img
                className="blinkrxcard-img"
                src={blinkcard}
                alt="Vascepa and BlinkRx logo"
              />
            </div>
          </div>
          <div className="chart-sidebar-no-chart-header grid grid-cols-12">
          <div className="blink-img m-b-20">
              <img
                className="blink-img"
                src={blinkcard2}
                alt="BlinkRx Card"
              />
            </div>
            <div class="blink-content">
              <p className="m-b-20 m-t-0 bold">E-prescribe VASCEPA to BlinkRx U.S.</p>
              <ul className="content-list">
                <li className="primary-list-item">
                  <strong>Exclusive program:</strong> Eligible, commercially insured patients can pay as low as $0 for 90 days and the most affordable cash pricing for patients not covered by insurance
                  <sup>&Dagger;</sup>
                </li>
                <li className="primary-list-item">
                  <strong>Immediate access:</strong> For patients with pending
                  prior authorization approvals, BlinkRx will provide a free
                  30-day fill to allow immediate access to VASCEPA
                  <sup>&sect;</sup>
                </li>
                <li className="primary-list-item">
                  <strong>Free, fast delivery nationwide:</strong> BlinkRx delivers the Rx, free, from one of 7,000+ licensed pharmacy partners around the country
                </li>
              </ul>
            </div>
          </div>
          <button
              data-modal="leavingsite-modal"
              data-href="https://www.blinkhealth.com/blinkrx/providers"
              onClick={goModal}
              className="cta-btn blinkrx-btn"
              style={{ margin: "25px 0 1.5em" }}
            >
              Learn more about BlinkRx
            </button>

        </div>
      </article>

      <div className="outcomes-callout"></div>

      <hr />
      <article id="request" className="mobile-m-t-25 endpoints">
        <h2 className="cv-headline m-b-5">Request samples</h2>
        <div className="sample-page-2-btn-container ">
          <Link className="homeBtn" to="/samples/">
            Get samples now
          </Link>
        </div>
      </article>
      <div className="outcomes-callout"></div>
      <hr/>
      <article name="rebate" id="rebate" className="endpoints">
        <h2 className="cv-headline m-b-5">Mail order rebate</h2>
        <div className="icon-lockup div-flex">
          <div className="icon-container">
            <img src={websiteIcon} alt="Dollar sign icon" />
          </div>
          <div className="icon-lockup-text">
            <p className="cv-body">
              If your patients obtain their VASCEPA prescription via a mail
              order program, click the link below to download an editable PDF
              form to determine reimbursement of a patient’s copayment or
              out-of-pocket expenses.
            </p>
          </div>
        </div>
        <a
          href="https://amarincorp.com/docs/Savings_Program_Rebate_Form.pdf"
          target="_blank"
          className="homeBtn"
        >
          Download form
        </a>
      </article>
      <div className="outcomes-callout"></div>
    </main>
  )
}
