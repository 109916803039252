import React, { Component } from "react"
import HeroImage from '../components/3.2_get_vascepa_now/HeroImage'
import Main from '../components/3.2_get_vascepa_now/Main'
import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import Modal from '../components/modal/Modal'
import HomeModal from '../components/modal/HomeModal'

import SEO from "../components/seo"

import Loader from "../components/loader"


export default class GVNow extends Component {

  constructor(props) {
    super(props)
    this.state = {
       stickyNav: false,
       isMobile: false,
       modal:  null,
       href: null
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }

  handleClick(e) {
    // console.log('clicked');
  }

  goModal = (modal,href) => {
    this.setState(() => ({ modal: modal, href:href }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null, href:null }))
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {

    return (
      <>
      <Loader>
      <SEO
        title="Get VASCEPA Now"
        keywords={[""]}
        description="VASCEPA: Prescribe eligible patients effective and affordable treatment. Request samples, receive a savings card, or get a mail order rebate."/>
        <HomeModal/>
        <Modal modal={this.state.modal} href={this.state.href} modalClosed={this.modalClosed}/>
        <Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        <div className="grad"></div>
        <HeroImage onClick={this.handleClick} bckgrdImage={'header3-2'}/>
        <div className="wrapper">
            <Main isMobile={this.state.isMobile} goModal={this.goModal}/>
        </div>
        <ISI scrollPos={this.state.unstick} disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product.">
        <div className="ref-outer">
        <div className="footnotes-container mobile-p-x-0">
          <div className="footnotes">
            <p className="cv-footnote hang">
              *Data based on analysis of DRG formulary data and Symphony claims
              data of patients’ final out-of-pocket cost, with eligible,
              commercially insured patients using the VASCEPA Savings Card. Data
              on file.
            </p>
            <p className="cv-footnote hang"><sup>&dagger;</sup>Universal Pharmacy Card (UPC) may be applied for any eligible patient by entering all 4 codes into the notes section of an e-prescription.</p>
            <p className="cv-footnote hang">
              <sup>&Dagger;</sup>This offer is valid only for eligible commercially insured patients with a valid VASCEPA<sup>&reg;</sup> (icosapent ethyl) prescription. Patients whose prescriptions are covered or reimbursable in whole or in part by Medicare (including Medicare Part D and Medicare Advantage), Medicaid, TRICARE, DOD or any other federal or state program are not eligible for this offer. Offer may be used by eligible patients for a maximum savings of $2400 annually, for up to $160 per 1-month fill and $480 per 3-month fill. Offer only available in the United States. Void in MA and where otherwise prohibited by law or restricted by a third party. It may not be redeemed for cash. It is not transferable and may not be combined with any other savings offer or used for any other product. This is not health insurance. BlinkRx and AmarinPharma, Inc. reserve the right to rescind, revoke or amend this offer at any time with or without notice. It is a violation of federal law to buy, sell or counterfeit this offer. Cash-paying patients not using insurance who acknowledge and agree they cannot submit any portion of such payment for reimbursement to any insurance or other third-party are alternatively eligible for a 30-day supply for $99.
            </p>
            <p className="cv-footnote hang">
              <sup>&sect;</sup>Available to eligible patients with commercial insurance coverage only. Patients must have a valid prescription for VASCEPA<sup>&reg;</sup> (icosapent ethyl) and a prior authorization request from their insurer on file. Subject to terms and conditions, program provides for a 30-day fill of VASCEPA at no charge to patients while their prior authorization request is being processed and is not contingent on purchase requirements of any kind. Program is not available to patients whose medications are reimbursed in whole or in part by Medicare (including Medicare Part D and Medicare Advantage), Medicaid, TRICARE, DOD or any other federal or state program. Offer subject to change or discontinuance without notice. This is not health insurance and program does not guarantee insurance coverage. No claims for payment may be submitted to any third party for product dispensed under this program. Limitations may apply.
            </p>
          </div>
        </div>
        <div className="footnotes-container mobile-p-x-0">
          <div className='references'>
            <p className="cv-footnote">
              <strong>Reference: 1. </strong>Kaiser Family Foundation. Employer health benefits: 2021 annual survey. https://files.kff.org/attachment/Report-Employer-Health-Benefits-2021-Annual-Survey.pdf. Accessed February 16, 2022.
              </p>
        </div>
          </div>
        </div>
        </ISI>
        
        <Footer />
        </Loader>
      </>
    )
  }
}
